import React from 'react';
import styled from 'styled-components';
import DuotoneFilter from '../../../utils/DuotoneFilter';
import theme from './theme';

interface ExcerptProps {
  excerpt: string;
  image: string;
}

const Excerpt: React.FC<ExcerptProps> = ({ excerpt, image }) => (
  <StyledExcerpt image={image}>
    <DuotoneFilter id="red" r="0.2 0.88" g="0.16 0.23" b="0.16 0.18" />
    <div className="excerpt-image"></div>
    <aside>{excerpt}</aside>
  </StyledExcerpt>
);

export default Excerpt;

interface StyledExcerptProps {
  image: string;
}

const StyledExcerpt = styled.div<StyledExcerptProps>`
  background-color: red;
  margin-top: 4rem;
  position: relative;
  .excerpt-image {
    position: absolute;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    width: 100%;
    height: 100%;
    z-index: 1;
    filter: url(#red);
  }
  aside {
    position: relative;
    color: white;
    text-align: left;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
    padding: 4rem 4rem;
    z-index: 10;
    max-width: ${theme.breakpoints.md};
    margin: 0 auto;
    @media screen and (min-width: ${theme.breakpoints.sm}) {
      font-size: 1.5rem;
      line-height: 1.625rem;
    }
  }
  svg {
    display: none;
  }
`;
