import React from 'react';
import { useForm } from 'react-hook-form';
import {
  useFormQualification,
  IFormData,
} from '../../../../hooks/useQualificationLogic';
import QualifierField from './QualifierField';
import JsonField from './JsonField';
import useUIStore from '../../../../state/useUIStore';
import useFormStore from '../../../../state/useFormStore';

const StepOne: React.FC = () => {
  const fields = useUIStore((state) => state.fields);
  const nextStep = useUIStore((state) => state.nextStep);
  const { updateQualifierData, setQualified } = useFormStore((state) => state);
  const formState = useFormStore((state) => state.qualifierData);
  const { register, handleSubmit, errors, watch } = useForm<IFormData>({
    defaultValues: { ...formState },
  });

  const formData = watch();

  const isQualified = useFormQualification(formData, fields);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit((data) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'gaFormSubmit',
        gaEventCategory: 'Forms',
        gaEventAction: 'View Content',
        gaEventLabel: formData.caseType,
      });
      updateQualifierData(data);
      setQualified(isQualified);
      nextStep();
    })(e);
  };

  return (
    <>
      <span className="form-header">Start your free consultation.</span>
      <form className="form-inner" onSubmit={onSubmit}>
        {fields.map((field) => {
          if (field._rawConditionalField.condition === 'standard') {
            const fieldData = field._rawConditionalField.standard;
            return (
              <QualifierField
                key={field._key}
                qualifier={fieldData}
                register={register}
              />
            );
          }
          const fieldData = JSON.parse(
            field._rawConditionalField.json.data.code,
          );
          return (
            <JsonField
              key={field._key}
              fields={fieldData}
              register={register}
            />
          );
        })}
        <button className="form-button" type="submit">
          Get Help
        </button>
        {Object.keys(errors).length > 0 && (
          <span className="error-prompt light">
            Please complete all required fields.
          </span>
        )}
      </form>
    </>
  );
};

export default StepOne;
