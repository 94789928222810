import React from 'react';
import { useForm } from 'react-hook-form';
import useUIStore from '../../../../state/useUIStore';
import useFormStore from '../../../../state/useFormStore';

interface FormData {
  [key: string]: string[] | string;
}

const StepTwo: React.FC = () => {
  const {
    qualifierData,
    trackingData,
    userData,
    updateUserData,
  } = useFormStore((state) => state);
  const { nextStep, prevStep } = useUIStore((state) => state);
  const [status, setFormStatus] = React.useState('');

  const { register, handleSubmit, errors, watch } = useForm<FormData>({
    defaultValues: {
      ...userData,
    },
  });

  const currentFormData = watch();

  const goBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    updateUserData(currentFormData);
    prevStep();
  };

  const flatten = (arr: string | string[]): string | null => {
    if (Array.isArray(arr)) {
      return arr.join(', ');
    }
    return null;
  };

  const sendToFormKeep = (formData: FormData, formkeepURL: string) => {
    //  If any qualifiers are checklist, flatten to single string.
    Object.keys(formData).forEach((key) => {
      if (Array.isArray(formData[key])) {
        formData[key] = flatten(formData[key]);
      }
    });

    fetch(formkeepURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(() => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'gaFormSubmit',
          gaEventCategory: 'Forms',
          gaEventAction: qualifierData.qualified
            ? 'Submission'
            : 'Complete Registration',
          gaEventLabel: formData.caseType,
        });
        setFormStatus('SUCCESS');
        nextStep();
      })
      .catch(() => {
        setFormStatus('ERROR');
      });
  };

  const onSubmit = (
    data: FormData,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    updateUserData(currentFormData);
    const payload = { ...currentFormData, ...qualifierData, ...trackingData };
    sendToFormKeep(payload, 'https://formkeep.com/f/3d79c0b4c49a');
  };

  return (
    <>
      <span className="form-header">We just need your information.</span>
      <form className="form-inner" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="firstName" className="input-label">
          First Name*
        </label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          ref={register({ required: true })}
          className={`form-text-input ${errors.firstName && 'field-error'}`}
          placeholder={errors.firstName && 'A first name is required'}
        />
        <label htmlFor="lastName" className="input-label">
          Last Name*
        </label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          ref={register({ required: true })}
          className={`form-text-input ${errors.lastName && 'field-error'}`}
          placeholder={errors.lastName && 'A last name is required'}
        />
        <label htmlFor="email" className="input-label">
          Email
        </label>
        <input
          type="text"
          name="email"
          id="email"
          ref={register}
          className="form-text-input"
        />
        <label htmlFor="phone" className="input-label">
          Phone
        </label>
        <input
          type="text"
          name="phone"
          id="phone"
          ref={register({ required: true })}
          className={`form-text-input ${errors.phone && 'field-error'}`}
          placeholder={errors.phone && 'A phone number is required'}
        />
        <label htmlFor="description" className="input-label">
          Case Info
        </label>
        <textarea
          name="description"
          id="description"
          ref={register({ required: true })}
          className={`form-text-input ${errors.description && 'field-error'}`}
          placeholder={
            errors.description && 'Please tell us more about your case.'
          }
        />
        <div className="form-button-group">
          <button onClick={goBack} className="form-button" type="button">
            Back
          </button>
          <button className="form-button" type="submit">
            Submit
          </button>
        </div>
        {Object.keys(errors).length > 0 && (
          <span className="error-prompt light">
            Please complete all required fields.
          </span>
        )}
        {status === 'ERROR' && (
          <>
            <span className="error-prompt light">
              ERROR: There was a problem submitting your information. Please try
              resending the form. If the problem persists, please give us a
              call.
            </span>
          </>
        )}
      </form>
    </>
  );
};

export default StepTwo;
