import React from 'react';
import BlockContent, {
  BlockContentProps,
} from '@sanity/block-content-to-react';
import styled from 'styled-components';
import theme from './theme';

interface BlockSectionProps {
  content: BlockContentProps;
  id: string;
}

const BlockSection: React.FC<BlockSectionProps> = ({ content, id }) => (
  <section id={id}>
    <Container>
      <BlockContent blocks={content} />
    </Container>
  </section>
);

const Container = styled.div`
  padding: 3rem 4rem 0 4rem;
  max-width: 960px;
  margin: 0 auto;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a {
    color: red;
  }

  p {
    color: ${theme.colors.black};
  }

  @media screen and (min-width: ${theme.breakpoints.md}) {
    a {
      font-size: 1.75em;
    }
    p {
      font-size: 1.2em;
      line-height: 1.375em;
    }
  }
`;

export default BlockSection;
