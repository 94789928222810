import React from 'react';
import { AnimatePresence, createDomMotionComponent } from 'framer-motion';
import StepOne from './FormStepOne';
import StepTwo from './FormStepTwo';
import StepThree from './FormStepThree';
import useUIStore from '../../../../state/useUIStore';
import styled from 'styled-components';
import theme from '../theme';

const motion = {
  div: createDomMotionComponent('div'),
};

const Form: React.FC = () => {
  const formStep = useUIStore((state) => state.formStep);

  return (
    <FormWrap>
      <AnimatePresence exitBeforeEnter>
        {formStep === 1 && (
          <motion.div
            className="form-inner"
            key="step1"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
          >
            <StepOne />
          </motion.div>
        )}
        {formStep === 2 && (
          <motion.div
            className="form-inner"
            key="step2"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
          >
            <StepTwo />
          </motion.div>
        )}
        {formStep === 3 && (
          <motion.div
            className="form-inner"
            key="step3"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
          >
            <StepThree />
          </motion.div>
        )}
      </AnimatePresence>
    </FormWrap>
  );
};

export default Form;

const FormWrap = styled.div`
  padding: 1rem;
  width: 100%;
  background: rgba(102, 128, 174, 0.7);
  grid-area: form;
  overflow: hidden;
  color: white;
  margin-bottom: 1rem;
  border-radius: 0.125rem;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    min-width: 250px;
    align-self: center;
  }
  .form-inner {
    display: flex;
    flex-direction: column;
  }

  .form-header {
    font-size: 1.75rem;
    font-weight: 800;
    line-height: 2rem;
    @media screen and (min-width: ${theme.breakpoints.md}) {
      font-size: 2rem;
    }
  }

  .input-label {
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: 600;
    margin: 0.75rem 0 0.25rem 0;
  }

  .check-label {
    margin-left: 0.25rem;
  }

  .select {
    font-family: ${theme.font};
    color: ${theme.colors.primary};
    font-size: 1.125rem;
    padding: 0.25em;
    border-radius: 0.125em;
  }
  .form-text-input {
    font-family: ${theme.font};
    color: ${theme.colors.primary};
    font-size: 1.125rem;
    border: none;
    border-radius: 0.125em;
    padding: 0.25em;
    transition: all 150ms ease-in-out;
    &:hover {
      background: #e4e4e4;
    }
  }

  .form-button {
    font-family: ${theme.font};
    background: ${theme.colors.secondary};
    border: none;
    color: white;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5em;
    border-radius: 0.125em;
    margin-top: 1rem;
    transition: all 100ms ease-in-out;
    &:hover {
      background: $secondary-active;
      cursor: pointer;
      transform: scale3d(1.025, 1.025, 1);
    }
    @media screen and (min-width: ${theme.breakpoints.md}) {
      font-size: 1.25rem;
    }
  }

  .form-button-group {
    display: flex;
    button {
      font-family: ${theme.font};
      font-size: 1rem;
      width: 100%;
      margin-top: 1rem;
      @media screen and (min-width: ${theme.breakpoints.md}) {
        font-size: 1.25rem;
      }
      &:first-of-type {
        margin-right: 0.675rem;
      }
    }
  }

  .field-error {
    border: solid ${theme.colors.secondary} 2px;
  }

  .error-prompt {
    margin-top: 1rem;
    line-height: 1rem;
    text-align: center;
  }

  .confirmation-video {
    width: 100%;
    margin-bottom: 0;
  }

  .confirmation-header {
    font-weight: 800;
  }

  .confirmation-list {
    font-size: 0.85rem;
    line-height: 1rem;
  }
`;
