import React from 'react';

const StepThree: React.FC = () => (
  <div>
    <span className="form-header">Thank you!</span>
    <iframe
      title="What happens next?"
      className="confirmation-video"
      src="https://player.vimeo.com/video/431904469"
      width="640"
      height="260"
      frameBorder="0"
      allow="autoplay; fullscreen"
      allowFullScreen
    />
    <p className="confirmation-header">What happens next?</p>
    <ul className="confirmation-list">
      <li>A firm from our network will be reaching out to you shortly. </li>
      <li>
        Our goal is to help answer any questions you may have and verify your
        eligibility in regards to a claim.
      </li>
      <li> We look forward to talking to you soon!</li>
    </ul>
  </div>
);
export default StepThree;
