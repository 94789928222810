import React from 'react';
import { ValidationRules, UseFormMethods } from 'react-hook-form';

interface Props extends Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  rules?: ValidationRules;
  qualifier: {
    name: string;
    label: string;
    values: string[];
  };
}

const QualifierSelect: React.FC<Props> = ({ qualifier, register }) => (
  <>
    <span className="input-label">{qualifier.label}</span>

    <select
      className="select"
      ref={register({
        required: true,
        validate: (value) => value !== 'Choose one',
      })}
      name={qualifier.name}
      defaultValue="Choose one"
    >
      <option disabled>Choose one</option>
      {qualifier.values.map((value) => (
        <option key={value} value={value}>
          {value}
        </option>
      ))}
    </select>
  </>
);

export default QualifierSelect;
