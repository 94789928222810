import React from 'react';
import { ValidationRules, UseFormMethods } from 'react-hook-form';

interface Props extends Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  rules?: ValidationRules;
  qualifier: {
    name: string;
    label: string;
    values: string[];
  };
}

const QualifierChecklist: React.FC<Props> = ({ qualifier, register }) => (
  <>
    <span className="input-label">{qualifier.label}</span>
    {qualifier.values.map((value, i) => (
      <div key={value}>
        <input
          ref={register({ required: true })}
          type="checkbox"
          name={qualifier.name}
          id={`${qualifier.name}_${i}`}
          value={value}
        />
        <label className="check-label">{value}</label>
        <br />
      </div>
    ))}
  </>
);

export default QualifierChecklist;
