import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { IQualifier } from '../../../../types/types';
import QualifierChecklist from './QualifierChecklist';
import QualifierRadio from './QualifierRadio';
import QualifierSelect from './QualifierSelect';

interface QualifierFieldProps
  extends Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  qualifier: IQualifier;
}

const QualifierField: React.FC<QualifierFieldProps> = ({
  qualifier,
  register,
}) => {
  if (qualifier === undefined) {
    return null;
  }
  switch (qualifier.layout) {
    case 'Dropdown List':
      return <QualifierSelect qualifier={qualifier} register={register} />;
    case 'Radio Buttons':
      return <QualifierRadio qualifier={qualifier} register={register} />;
    case 'Checklist':
      return <QualifierChecklist qualifier={qualifier} register={register} />;
    default:
      return <div />;
  }
};

export default QualifierField;
