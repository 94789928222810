//  ARES TEMPLATE

//  BEGIN IMPORTING MODULES
//  Key Imports
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { createDomMotionComponent } from 'framer-motion';
import GlobalStyles from './components/GlobalStyles';
//  Layout Components
import Header from './components/Header';
import BlockSection from './components/BlockSection';
import Excerpt from './components/Excerpt';
import Featured from './components/Featured';
import Footer from './components/Footer';
//  State Management
import useFormStore from '../../state/useFormStore';
import useUIStore from '../../state/useUIStore';
//  SEO and Tracking
import Helmet from 'react-helmet';
import { detect } from 'detect-browser';
import { useLocation } from '@reach/router';
import * as queryString from 'query-string';
//  Types
import { SanityPageProps } from '../../types/types';
//  END OF MODULE IMPORTS

//  Animation Wrapper for Animated Elements
const motion = {
  div: createDomMotionComponent('div'),
};

//  Initiate Tracking Pixel
const generatePixelParams = (platform: string, pixel: string) => {
  switch (platform) {
    case 'Facebook':
      return { fbPixel: pixel || '540015609884702' };
    case 'Snapchat':
      return { scPixel: pixel || '' };
    default:
      return {};
  }
};

//  Template Component
const Template: React.FC<SanityPageProps> = ({ data }) => {
  //  Determine qualification fields setup in Sanity
  const generateFields = useUIStore((state) => state.generateFields);
  const { updateTrackingData } = useFormStore((state) => state);

  //  Page Specific Props
  const { content, campaign, title, phoneOverride } = data.sanityPage;

  //  Brand Specific Props
  const { brand, trackingPixel, platform } = campaign;

  //  Content Specific Props
  const {
    qualifyingFields,
    headline,
    subHeader,
    excerpt,
    _rawLawsuitInformation,
    _rawPotentialRisksAndInjuries,
    _rawCta,
    mainImage,
    secondaryImage,
  } = content;

  //  Grab the case_type and other query params
  const location = useLocation();
  const { case_type, ...query } = queryString.parse(location.search);

  //  Once the page renders, we update our state and data layers with
  //  information about the user, url params, and the user's browser.
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      ...generatePixelParams(platform.name, trackingPixel),
    });
    const browser = detect();
    generateFields(qualifyingFields.qualifiers);

    const trackingData = {
      url: `${location.origin}${location.pathname}`,
      marketingId: campaign.marketingId,
      caseType: campaign.caseTypeOverride
        ? campaign.caseTypeOverride
        : campaign.caseTypeName.name
        ? campaign.caseTypeName.name
        : case_type
        ? case_type
        : '',
      platform: campaign.platform.name,
      vendor: campaign.vendor.name,
      ...query,
      browserName: browser === null ? '' : browser.name,
      browserVersion: browser === null ? '' : browser.version,
      browserType: browser === null ? '' : browser.type,
      os: browser === null ? '' : browser.os,
    };

    updateTrackingData(trackingData);
  }, []);
  /*eslint-disable */

  //  JSX markup for template
  return (
    <>
      <GlobalStyles />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: 'easeOut', duration: 1.25 }}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={subHeader} />
        </Helmet>
        <div>
          <Header
            headline={headline}
            subHeader={subHeader}
            image={mainImage.asset.fluid.src}
            logo={brand.logo.asset.fixed}
            phone={phoneOverride ? phoneOverride : brand.phone}
          />
        </div>
        <BlockSection
          id="lawsuit_information"
          content={_rawLawsuitInformation}
        />
        <Excerpt excerpt={excerpt} image={secondaryImage.asset.fluid.src} />
        <BlockSection
          id="lawsuit_information"
          content={_rawPotentialRisksAndInjuries}
        />
        <BlockSection id="lawsuit_information" content={_rawCta} />
        <Featured
          filterId="white"
          featured={brand.featured}
          logoColor="#fff"
          opacity={0.5}
        />
        <Footer brand={brand} />
      </motion.div>
    </>
  );
};

export default Template;

//  GraphQL Query
export const query = graphql`
  query($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      campaign {
        marketingId
        caseTypeName {
          name
        }
        caseTypeOverride
        platform {
          name
        }
        vendor {
          name
        }
        trackingPixel
        brand {
          name
          legalName
          phone
          tos
          logo {
            asset {
              fixed(width: 200) {
                ...GatsbySanityImageFixed
              }
            }
          }
          featured {
            _key
            asset {
              fluid(maxWidth: 150) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      phoneOverride
      content {
        qualifyingFields {
          qualifiers {
            _key
            _rawConditionalField
          }
        }
        headline
        subHeader
        excerpt
        _rawLawsuitInformation
        _rawPotentialRisksAndInjuries
        _rawCta

        mainImage {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
        secondaryImage {
          asset {
            fluid(maxWidth: 1920) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
